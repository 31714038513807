import React, { useState, useContext } from 'react'
import { Container, Row, Col } from 'reactstrap'
import Slider from 'rc-slider'
import { getPrices } from '../data/prices'

import Link from './Link'

import './PricingWidgetSection.scss'
import LocaleContext from './LocaleContext'

export default ({ theme = 'dark' }) => {
  const locale = useContext(LocaleContext)
  const [people, setPeople] = useState(10)
  const prices = getPrices(locale.value)

  /**
   * New subscription cost calculation
   * See README-subscription-price-calculation.md
   */
  const calculatePrice = people => {
    const price = prices
      .sort((a, b) =>
        a.tier_up_to < b.tier_up_to ? -1 : a.tier_up_to == b.tier_up_to ? 0 : 1
      )
      .find(({ tier_up_to }) => {
        if (tier_up_to === null) return true
        return people <= tier_up_to
      })
    if (!price) {
      return '??'
    }
    return price.tier_price_mode === 'flat' ? price.price : people * price.price
  }

  //
  const GetStartedButton = (
    <Link color="cyan" type="button" href="/book-a-demo" localePrefix={true}>
      Get Started
    </Link>
  )

  //
  const PeopleText = () => {
    if (people === 1) return '1 person'

    return `${people} people`
  }

  //
  const PriceText = () => {
    if (people === 1) return <b>Free for 15 days</b>
    else if (people >= 251) return <b>CALL US</b>

    return (
      <>
        <b>${calculatePrice(people)}/month </b>
        <small>
          +GST <b>{locale.currency}</b>
        </small>
        <br />
      </>
    )
  }

  return (
    <section
      className={
        'PricingWidgetSection py-4' +
        (theme !== 'dark' ? ` theme-${theme}` : '')
      }
    >
      <Container>
        <Row>
          <Col md={6} className="d-flex">
            <div className="PricingWidgetSection__text w-100 my-auto pt-5 pb-3 text-center py-md-5 text-md-left">
              <h2 className="PricingWidgetSection__title h1">
                Simple monthly fee.
                <br /> No lock-in contracts.
              </h2>
              <div className="d-none d-md-block my-4">{GetStartedButton}</div>
            </div>
          </Col>
          <Col md={6} className="d-flex">
            <div className="PricingWidgetSection__widget w-100 m-auto pb-5 py-md-5 text-center">
              <div className="PricingWidgetSection__people mb-2">
                <PeopleText />
              </div>
              <div className="PricingWidgetSection__price">
                <PriceText />
              </div>
              <Slider
                min={1}
                max={251}
                defaultValue={people}
                onChange={val => setPeople(val)}
              />
              <div className="PricingWidgetSection__fee mb-2">
                + ONE-TIME SETUP FEE
              </div>
              <p className="PricingWidgetSection__copy">
                The setup fee is a one-off cost and covers a full company HR
                audit, health check and MyHR software implementation.
              </p>
              <div className="d-md-none">{GetStartedButton}</div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
