import React, { useEffect, useContext } from 'react'
import { Container, Row, Col, Button } from 'reactstrap'

import Layout from '../../components/Layout'
import Hero from '../../components/Hero'
import Section from '../../components/Section'
import PricingWidgetSection from '../../components/PricingWidgetSection'
import BoxedCTASection from '../../components/BoxedCTASection'
import RichText from '../../components/RichText'
import Link, { addLocalePrefix } from '../../components/Link'
import LocaleContext from '../../components/LocaleContext'
import SubscribeForm from '../../components/SubscribeForm'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook } from '@fortawesome/free-solid-svg-icons'

import '../../templates/landing-page.scss'
import './sme-employment-employer-insights-report-2023.scss'
import image from '../../images/section/subscribe2.png'
import image2 from '../../images/hero/23-206 - MyHR - Landing Page Book mockup.png'

class HubspotForm extends React.Component {

  constructor(props) {
	super(props)
  }

  componentDidMount() {
	const script = document.createElement('script');
	script.src = 'https://js.hsforms.net/forms/v2.js';
	document.body.appendChild(script);
	
	script.addEventListener('load', () => {
	  if(window.hbspt) {
		window.hbspt.forms.create({
		  portalId: '3837251',
		  formId: '6fa224f8-9084-4a7b-b077-4e5ba7e58e4b',
		  target: '#hubspotForm'
		})
	  }
	});
  }
  
  render() {
	return (
	  <div>
		<div id="hubspotForm"></div>
	  </div>
	);
  }
}

export default ({data}) => {
  const locale = useContext(LocaleContext)

  return (
	<Layout
	  className={`LandingPage SmeReport`}
	  title={``}
	  locale={`en-NZ`}
	>
	  <Hero>
		<Container>
		  <Row>
			<Col lg={8} className="pl-0 pr-sm-6 pr-xs-0 d-flex flex-column justify-content-center align-items-start">
			  <h5 className="tag">
			  	<FontAwesomeIcon
					icon={faBook}
					className="icon play-icon"
			  	/> 
			  	&nbsp; NZ MARKET REPORT
			  </h5>
			  <h1>SME Employment & Employer Insights Report 2023</h1>
			  <div className="intro">
			  	A snapshot of the SME employment landscape in New Zealand, 
				  including salary averages, wider employment trends, and 
				  results from our inaugural SME employer survey
			  </div>
			  <Button
			  	color="cyan"
			  	href="#scrollToForm"
			  	className="mr-3"
			  >
			  	Download free report
			  </Button>
			</Col>
			<Col lg={4}>
			  <img src={image2} />
		    </Col>
		  </Row>
		</Container>
	  </Hero>

	  <Section className="LandingPage__Main pt-5 pb-2" id="scrollToForm">
		<Container className="py-6">
		  <Row>
			<Col lg={7} className="pb-5 referral-text">
			  <h2>In this report</h2>
			  <p className="introText">MyHR has a client base of over 1,200 organisations in NZ, with 
			  more than 29,000 employees on our platform, so we are 
			  uniquely positioned to understand the everyday challenges 
			  that SMEs face.</p>
			  <p>We have leveraged this database to deliver never-before-seen insights into salary 
			  and employment activity over the past year and complemented this with our 
			  first-ever business confidence survey. More than 400 business owners and 
			  managers have shared their reflections and intentions for the year ahead, which 
			  has painted an optimistic picture against which we can frame the trends of 2023.</p>
			  <p><strong>Key takeaways:</strong></p>
			  <ul>
			  	<li>Business confidence remains cautiously optimistic, despite the challenging backdrop.</li>
				<li>Restructuring activity is up in Q1 FY24.</li>
				<li>Pay rates continue to climb, but showing signs of slowing.</li>
				<li>Keeping existing staff engaged and productive is a top business priority in FY24.</li>
			  </ul>
			</Col>

			<Col lg={5} className="pb-5">
			  <HubspotForm />
			</Col>

		  </Row>
		</Container>
	  </Section>
	  
	  <Section className="SubscribeSection py-0">
	  	<Container>
			<Row>
		  	<Col md={{ size: 6, offset: 0 }} lg={{ size: 6, offset: 1 }}>
				<div className="h-100 d-flex">
			  	<div className="SubscribeSection__text my-auto">
					<h2>Stay up to date.</h2>
					<p>Get our latest HR blogs, guides & videos delivered straight to your inbox.</p>
					<SubscribeForm
				  	buttonLabel="Subscribe"
				  	themeColor="cyan"
				  	textColor="white"
				  	thankyouMessage={
						<div>
					  	<h2>Thanks for subscribing!</h2>
					  	<p>Get ready to make HR easy.</p>
						</div>
				  	}
					/>
			  	</div>
				</div>
		  	</Col>
		  	<Col md={6} lg={4} className="d-none d-md-block">
				<div className="SubscribeSection__image">
			  	<img src={image} alt="HR Expert" />
				</div>
		  	</Col>
			</Row>
	  	</Container>
	  </Section>

	</Layout>
  );
}