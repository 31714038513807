export const defaultLocale = 'en_NZ'
export const defaultCountry = 'NZ'

// Locations / regions supported
export const defaultLocations = [
  {
    country_iso: 'NZ',
    country_name: 'New Zealand',
    region_iso: null,
    region_name: null,
    locale: 'en_NZ',
    timezone: 'Pacific/Auckland',
  },
  {
    country_iso: 'AU',
    country_name: 'Australia',
    region_iso: 'NSW',
    region_name: 'New South Wales',
    locale: 'en_AU',
    timezone: 'Australia/Sydney',
  },
  {
    country_iso: 'AU',
    country_name: 'Australia',
    region_iso: 'QLD',
    region_name: 'Queensland',
    locale: 'en_AU',
    timezone: 'Australia/Brisbane',
  },
  {
    country_iso: 'AU',
    country_name: 'Australia',
    region_iso: 'SA',
    region_name: 'South Australia',
    locale: 'en_AU',
    timezone: 'Australia/Adelaide',
  },
  {
    country_iso: 'AU',
    country_name: 'Australia',
    region_iso: 'TAS',
    region_name: 'Tasmania',
    locale: 'en_AU',
    timezone: 'Australia/Hobart',
  },
  {
    country_iso: 'AU',
    country_name: 'Australia',
    region_iso: 'VIC',
    region_name: 'Victoria',
    locale: 'en_AU',
    timezone: 'Australia/Melbourne',
  },
  {
    country_iso: 'AU',
    country_name: 'Australia',
    region_iso: 'WA',
    region_name: 'Western Australia',
    locale: 'en_AU',
    timezone: 'Australia/Perth',
  },
  {
    country_iso: 'AU',
    country_name: 'Australia',
    region_iso: 'ACT',
    region_name: 'Australian Capital Territory',
    locale: 'en_AU',
    timezone: 'Australia/Sydney',
  },
  {
    country_iso: 'AU',
    country_name: 'Australia',
    region_iso: 'NT',
    region_name: 'Northern Territory',
    locale: 'en_AU',
    timezone: 'Australia/Darwin',
  },

  /* 

  Singapore & the United Kingdom are not supported yet
  {
    country_iso: 'SG',
    country_name: 'Singapore',
    region_iso: null,
    region_name: null,
    locale: 'en_NZ',
    timezone: 'Asia/Singapore',
  },

  {
    country_iso: 'GB',
    country_name: 'United Kingdom',
    region_iso: null,
    region_name: null,
    locale: 'en_NZ',
    timezone: 'Europe/London',
  },

  */

  {
    country_iso: 'CA',
    country_name: 'Canada',
    region_iso: 'AB',
    region_name: 'Alberta',
    locale: 'en_NZ',
    timezone: 'Canada/Mountain',
  },
  {
    country_iso: 'CA',
    country_name: 'Canada',
    region_iso: 'BC',
    region_name: 'British Columbia',
    locale: 'en_NZ',
    timezone: 'Canada/Pacific',
  },
  {
    country_iso: 'CA',
    country_name: 'Canada',
    region_iso: 'MB',
    region_name: 'Manitoba',
    locale: 'en_NZ',
    timezone: 'Canada/Central',
  },
  {
    country_iso: 'CA',
    country_name: 'Canada',
    region_iso: 'NB',
    region_name: 'New Brunswick ',
    locale: 'en_NZ',
    timezone: 'Canada/Eastern',
  },
  {
    country_iso: 'CA',
    country_name: 'Canada',
    region_iso: 'NL',
    region_name: 'Newfoundland and Labrador',
    locale: 'en_NZ',
    timezone: 'Canada/Atlantic',
  },
  {
    country_iso: 'CA',
    country_name: 'Canada',
    region_iso: 'NS',
    region_name: 'Nova Scotia',
    locale: 'en_NZ',
    timezone: 'Canada/Atlantic',
  },
  {
    country_iso: 'CA',
    country_name: 'Canada',
    region_iso: 'ON',
    region_name: 'Ontario',
    locale: 'en_NZ',
    timezone: 'Canada/Eastern',
  },
  {
    country_iso: 'CA',
    country_name: 'Canada',
    region_iso: 'PE',
    region_name: 'Prince Edward Island',
    locale: 'en_NZ',
    timezone: 'Canada/Atlantic',
  },
  {
    country_iso: 'CA',
    country_name: 'Canada',
    region_iso: 'QC',
    region_name: 'Quebec',
    locale: 'en_NZ',
    timezone: 'Canada/Eastern',
  },
  {
    country_iso: 'CA',
    country_name: 'Canada',
    region_iso: 'SK',
    region_name: 'Saskatchewan',
    locale: 'en_NZ',
    timezone: 'Canada/Central',
  },
  {
    country_iso: 'CA',
    country_name: 'Canada',
    region_iso: 'NT',
    region_name: 'Northwest Territories',
    locale: 'en_NZ',
    timezone: 'Canada/Mountain',
  },
  {
    country_iso: 'CA',
    country_name: 'Canada',
    region_iso: 'NU',
    region_name: 'Nunavut',
    locale: 'en_NZ',
    timezone: 'Canada/Eastern',
  },
  {
    country_iso: 'CA',
    country_name: 'Canada',
    region_iso: 'YT',
    region_name: 'Yukon',
    locale: 'en_NZ',
    timezone: 'Canada/Mountain',
  },
]

export const getLocations = () => {
  return {
    then: () => cb => {
      return cb(defaultLocations)
    },
  }
}

export default defaultLocations
