import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import Section from './Section'
import Link from './Link'

import SubscribeForm from './SubscribeForm'

import './BoxedCTASection.scss'
import lovingContentImage from '../images/icons/newsletter.png'
import resourcesImage from '../images/icons/resources.png'


export default  () =>
  <Section className="BoxedCTASection bg-gray-triangle">
    <Container>
      <Row>
        <Col md={6} className="mb-4 mb-md-0">
          <div className="BoxedCTASection__box d-flex shadow h-100">
            <div className="my-auto w-100">
              <div className="BoxedCTASection__box-title mb-1">Stay up to date.</div>
              <SubscribeForm textColor="electric-blue"/>
            </div>
          </div>
        </Col>

        <Col md={6}>
          <Link
            className="BoxedCTASection__box d-flex shadow h-100"
            href="/resources#guides"
            localePrefix={true}
          >
            <div className="my-auto">
              <div className="BoxedCTASection__box-title mb-1">Free HR Resources.</div>
              <div>Get your HR house in order with our free how-to guides & impact guides.</div>
            </div>
            <img
              className="BoxedCTASection__box-image"
              src={resourcesImage}
              alt="Free HR Resources"
            />
          </Link>
        </Col>
      </Row>
    </Container>
  </Section>

