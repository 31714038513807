import { defaultLocale } from './locations'

/**
 * Update this by running the pricebook-export in api billing.
 *
 * See https://www.notion.so/myhr-force/HubSpot-price-book-519a621235cb40e0ba4816e2e70dcc34#4309e3404ac24f3391ae49f3184579ba
 *
 * Then copy the contents from the .json files into here.
 *
 * fields useful:
 * - id
 * - locale
 * - name
 *
 * fields used:
 * - person_limit
 * - is_no_limit
 * - is_free
 * - min_people
 * - max_people
 * - base_people
 * - is_subscription_calculated_per_person_within_tier
 * - base_cost
 * - per_person_cost_within_tier
 *
 * https://app.myhr.works/api/package?is_custom=0
 * AND
 * https://app.myhr.works/api/package?is_custom=0&locale=en_AU
 */
export const NZPrices = [
  {
    id: 'price_1NuirDDVZ48hocDinOUZFbWU-20',
    name: 'NZ MyHR membership SML Bus 11-20',
    tier_up_to: 20,
    tier_price_mode: 'flat',
    price: 395,
    unit_label: 'person',
  },
  {
    id: 'price_1NuirDDVZ48hocDinOUZFbWU-100',
    name: 'NZ MyHR membership MED Bus 21-100',
    tier_up_to: 100,
    tier_price_mode: 'unit',
    price: 20,
    unit_label: 'person',
  },
  {
    id: 'price_1NuirDDVZ48hocDinOUZFbWU-250',
    name: 'NZ MyHR membership ENT Bus 101-250',
    tier_up_to: 250,
    tier_price_mode: 'unit',
    price: 18,
    unit_label: 'person',
  },
  {
    id: 'price_1NuirDDVZ48hocDinOUZFbWU-250+',
    name: 'NZ MyHR membership ENT Bus 251+',
    tier_up_to: null,
    tier_price_mode: 'unit',
    price: 16,
    unit_label: 'person',
  },
  {
    id: 'price_1NuipYDVZ48hocDiFQFtcs2M-10',
    name: 'NZ MyHR membership SML Bus 1-10',
    tier_up_to: 10,
    tier_price_mode: 'flat',
    price: 295,
    unit_label: 'person',
  },
]

export const AUPrices = [
  {
    id: 'price_1NujxxER8azTK4QWyePHPu3h-20',
    name: 'AU MyHR Membership SML Bus 11-20',
    tier_up_to: 20,
    tier_price_mode: 'flat',
    price: 395,
    unit_label: 'person',
  },
  {
    id: 'price_1NujxxER8azTK4QWyePHPu3h-100',
    name: 'AU MyHR Membership MED Bus 21-100',
    tier_up_to: 100,
    tier_price_mode: 'unit',
    price: 20,
    unit_label: 'person',
  },
  {
    id: 'price_1NujxxER8azTK4QWyePHPu3h-250',
    name: 'AU MyHR Membership ENT Bus 101-250',
    tier_up_to: 250,
    tier_price_mode: 'unit',
    price: 18,
    unit_label: 'person',
  },
  {
    id: 'price_1NujxxER8azTK4QWyePHPu3h-250+',
    name: 'AU MyHR Membership ENT Bus 251+',
    tier_up_to: null,
    tier_price_mode: 'unit',
    price: 16,
    unit_label: 'person',
  },
  {
    id: 'price_1NujwOER8azTK4QWH2RxPuza-10',
    name: 'AU MyHR Membership SML Bus 1-10',
    tier_up_to: 10,
    tier_price_mode: 'flat',
    price: 295,
    unit_label: 'person',
  },
]

export const CAPrices = [
  {
    id: 'price_1OlImxBDoAYAX7Zp7ukvAsxb-20',
    name: 'NZ MyHR membership SML Bus 11-20',
    tier_up_to: 20,
    tier_price_mode: 'flat',
    price: 395,
    unit_label: 'person',
  },
  {
    id: 'price_1OlImxBDoAYAX7Zp7ukvAsxb-100',
    name: 'NZ MyHR membership MED Bus 21-100',
    tier_up_to: 100,
    tier_price_mode: 'unit',
    price: 20,
    unit_label: 'person',
  },
  {
    id: 'price_1OlImxBDoAYAX7Zp7ukvAsxb-250',
    name: 'NZ MyHR membership ENT Bus 101-250',
    tier_up_to: 250,
    tier_price_mode: 'unit',
    price: 18,
    unit_label: 'person',
  },
  {
    id: 'price_1OlImxBDoAYAX7Zp7ukvAsxb-250+',
    name: 'NZ MyHR membership ENT Bus 251+',
    tier_up_to: null,
    tier_price_mode: 'unit',
    price: 16,
    unit_label: 'person',
  },
  {
    id: 'price_1OlImxBDoAYAX7ZpAoUZ6ycR-10',
    name: 'NZ MyHR membership SML Bus 1-10',
    tier_up_to: 10,
    tier_price_mode: 'flat',
    price: 295,
    unit_label: 'person',
  },
]

export const getPrices = (locale = defaultLocale) => {
  if (locale === 'en-NZ') {
    return NZPrices
  } else if (locale === 'en-CA') {
    return CAPrices
  } else if (locale === 'en-AU') {
    return AUPrices
  } else {
    return null
  }
}
